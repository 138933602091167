export const environment = {
  production: true,
  algoliaAppId: 'YO4ODTZMXC',
  api: 'https://api-dev.incendi.io',
  authConfig: {
    clientID: 'vT1Pf9VjohJT3efCJ9olgDXPXfp2zuN3',
    domain: 'login-dev.incendi.io'
  },
  contentfulApiUrl: 'https://australia-southeast1-incendi-ac400.cloudfunctions.net/contentful_api',
  cookiesDomain: 'incendi.io',
  firebase: {
    apiKey: 'AIzaSyCltOS-CK32rvFEpZwzuaAtLWhfQNMkM2I',
    appId: '1:696381138581:web:a4b26696fa5a977ef6dd9c',
    authDomain: 'incendi-ac400.firebaseapp.com',
    messagingSenderId: '696381138581',
    projectId: 'incendi-ac400',
    storageBucket: 'incendi-ac400.appspot.com'
  },
  gtmId: 'GTM-MB8D4CL',
  zendeskKey: 'adf89b4e-44d8-40c6-8b58-f0188d73fefe'
};
